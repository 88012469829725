import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { format } from "date-fns";
import Head from "next/head";

import {
  Container,
  AnimateIn,
  Code,
  Title,
  Quote,
  Button
} from "@/components";
import { PostMeta } from "@/types";

import {
  StyledWrapper,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledP,
  StyledA,
  StyledUl,
  StyledOl,
} from "./styled";

export const mdComponents = {
  h2: ({ children }: { children: React.ReactNode }) => (
    <StyledH2>{children}</StyledH2>
  ),
  h3: ({ children }: { children: React.ReactNode }) => (
    <StyledH3>{children}</StyledH3>
  ),
  h4: ({ children }: { children: React.ReactNode }) => (
    <StyledH4>{children}</StyledH4>
  ),
  p: ({ children }: { children: React.ReactNode }) => (
    <StyledP>{children}</StyledP>
  ),
  a: ({ children, ...rest }: { children: React.ReactNode }) => (
    <StyledA {...rest}>{children}</StyledA>
  ),
  ul: ({ children }: { children: React.ReactNode }) => (
    <StyledUl>{children}</StyledUl>
  ),
  ol: ({ children }: { children: React.ReactNode }) => (
    <StyledOl>{children}</StyledOl>
  ),
  code: ({ children, ...rest }: { children: React.ReactNode }) => (
    <Code {...rest}>{children}</Code>
  ),
  blockquote: ({ children, ...rest }: { children: React.ReactNode }) => (
    <Quote {...rest}>{children}</Quote>
  ),
};

const Post: React.FC<{ meta: PostMeta, banner?: React.ReactNode }> = ({ children, meta, banner }) => {
  return (
    <>
      <Head>
        <title>{meta.title} | Luke Brown</title>
        <meta name="description" content={meta.subtitle} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.subtitle} />
        <meta
          property="og:image"
          content={`https://og.lukebrown.io/${encodeURIComponent(meta.title)}`}
        />
      </Head>

      <Container>
        <AnimateIn>
          <div className="mt-6 leading-extra-relaxed mx-auto md:mt-8">
              {banner && <div>{banner}</div>}

              <Title>
                {meta.title}
              </Title>
              <p className="font-semiBold mt-2 opacity-50 lg:text-lg font-title">{format(new Date(meta.date), "PPPP")}</p>


            <StyledWrapper>
              <MDXProvider components={mdComponents}>{children}</MDXProvider>
            </StyledWrapper>

            <div className="mt-12">
              <span className="block border-b border-whiteopacity-50" />
              <div className="flex items-center mt-8">
                <div className="leading-relaxed w-full">
                  <div className="md:flex md:items-start md:justify-between">
                    <p className="max-w-xs font-title">Hey there 👋 - I'm <a href="https://twitter.com/Lukejohnbrown" target="_blank" rel="noreferrer" className="underline">Luke Brown</a>, a frontend web developer who often doesn't write about development at all.</p>
                    <div className="mt-4 md:mt-0">
                      <Button href="/writing" variant="BLACK" text="Read More articles" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </AnimateIn>
      </Container>
    </>
  );
};

export default Post;
