import React from "react";

import { ImageProps } from "./types";

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  caption,
  withBrowser = false,
}) => (
  <figure className="my-8">
    <img src={src} alt={alt} className="w-full" />

    {caption && (
      <figcaption className="font-semiBold leading-tight py-3 px-4 text-sm bg-black rounded-bl-md rounded-br-md">
        {caption}
      </figcaption>
    )}
  </figure>
);

export default Image;
