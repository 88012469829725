import React from "react";
import Link from "next/link";

import { Button, PostLink } from "@/components";
import { getAllPosts } from "@/util";
import { Post } from "@/types";

import { LatestPostsProps } from "./types";

const LatestPosts: React.FC<LatestPostsProps> = ({
  count,
}) => (
  <div>
    <div className="flex justify-between items-end">
      <h3 className="text-white font-extraBold text-xl sm:text-xl md:text-2xl">
        {count ? "Latest Posts" : "Thoughts from a web developer"}
      </h3>
      {count && (
        <Button href="/writing" variant="BLACK" text="All posts" />
      )}
    </div>

    <span className="block border-b border-white mt-3 opacity-50" />

    {getAllPosts
      .slice(0, count || getAllPosts.length)
      .map((post: Post, idx: number) => (
        <article
          key={idx}
          className={idx === 0 ? "mt-8" : "mt-10 md:mt-12"}
        >
          <PostLink {...post} />
        </article>
      ))}
  </div>
);

export default LatestPosts;
