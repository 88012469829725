import styled from "styled-components";
import tw from "twin.macro";

export const StyledH2 = styled.h2`
  ${tw`mt-10 text-xl font-extraBold leading-snug lg:text-2xl`}
  & + p {
    margin-top: 1rem !important;
  }
`;

export const StyledH3 = styled.h3`
  ${tw`mt-10 text-lg font-extraBold leading-relaxed lg:text-xl`}
  & + p {
    margin-top: 1rem !important;
  }
`;

export const StyledH4 = styled.h4`
  ${tw`mt-8 font-bold leading-relaxed`}
`;

export const StyledP = styled.p`
  &:not(:first-child) {
    ${tw`mt-6`}
  }
`;

export const StyledA = styled.a`
  ${tw`text-orange underline hover:no-underline`}
`;

export const StyledQuote = styled.blockquote`
  ${tw`font-bold italic mt-4 pl-6`}
`;

export const StyledUl = styled.ul`
  ${tw`mt-4`}
  li {
    ${tw`relative pl-5`}
    &:not(:first-child) {
      ${tw`mt-4`}
    }

    &::before {
      ${tw`w-2 h-2 absolute bg-orange`}
      top: 0.65rem;
      left: 0;
      border-radius: 50%;
      content: "";
    }

    ul {
      ${tw`pl-2 mt-2`};
      li {
        
        &::before {
          ${tw`border-2 border-orange bg-transparent`}
        }
        &:not(:first-child) {
          ${tw`mt-2`}
        }
      }
    }
  }
`;

export const StyledOl = styled.ol`
  ${tw`mt-4`}
  li {
    ${tw`relative pl-5`}
    counter-increment: step-counter;
    &:not(:first-child) {
      ${tw`mt-4`}
    }

    &::before {
      ${tw`absolute text-sm font-bold text-orange`}
      top: 0.126rem;
      left: 0;
      border-radius: 50%;
      content: counter(step-counter);
    }
  }
`;

export const StyledWrapper = styled.article`
  ${tw`mt-6 leading-extra-relaxed`}

  img {
    ${tw`block w-full`}
  }

  p code {
    ${tw`bg-black text-sm leading-tight px-1 py-1 font-semiBold rounded border border-grey`}
  }

  pre::-webkit-scrollbar {
    display: none;
  }
`;
