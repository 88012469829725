import { Post } from "@/types";

const importAll = (r: any) =>
  r.keys().map((fileName: string) => ({
    link: fileName.substr(1).replace(/\/index\.mdx$/, ""),
    module: r(fileName),
  }));

export const getAllPosts = importAll(
  require.context("../pages/writing/", true, /\.mdx$/)
).sort(
  (a: Post, b: Post) =>
    new Date(b.module.meta.date).getTime() -
    new Date(a.module.meta.date).getTime()
);
