import styled from "styled-components";
import tw from "twin.macro";


export const StyledBanner = styled.div<{
  isSnug: boolean;
}>`
  ${tw`sm:pt-10 lg:pt-8`}
  ${({ isSnug }) =>
    isSnug ? tw`pb-10 pt-4 md:pb-12` : tw`pb-12 pt-6 md:pb-16`}
`;

export const StyledTitle = styled.h1`
  ${tw`font-extraBold text-3xl leading-tight xs:text-4xl lg:text-4.5xl lg:leading-tight`}
`;

export const StyledSubtitle = styled.h2`
  ${tw`text-lg leading-relaxed mt-4 xs:text-xl lg:text-2xl lg:mt-4`}
`;

export const StyledBody = styled.p`
  ${tw` mt-6 leading-relaxed xs:text-lg xs:mt-8`}
`;

export const StyledButtons = styled.div`
  ${tw`mt-8 xs:flex xs:mt-10`}

  button,
  a {
    ${tw`block xs:inline-block`}
    &:first-child {
      ${tw`mb-2 xs:mb-0 xs:mr-4`}
    }
  }
`;
