
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer, Notice } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "What is optional chaining in JavaScript?",
  subtitle: "Optional chaining will soon be coming to JavaScript (at the time of writing it is in stage 4). Let's take a quick look at what optional chaining is and why you should be excited about it.",
  categories: "Javascript, ES2020",
  date: "2020-04-27",
  slug: "what-is-optional-chaining-in-javascript"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Optional chaining will soon be coming to JavaScript (at the time of writing it is in stage 4). Let's take a quick look at what optional chaining is and why you should be excited about it.`}</p>
    <p>{`Put simply...`}</p>
    <Notice mdxType="Notice">Optional chaining is a way of accessing nested object values without first having to validate that they exist.</Notice>
    <p>{`As you can see in the following examples, the syntax introduced by optional usually results in a shorter, simpler expression when reading nested object values.`}</p>
    <Spacer space="12" mdxType="Spacer" />
    <h2>{`Using optional chaining in JavaScript`}</h2>
    <p>{`In this example, we have a `}<inlineCode parentName="p">{`house`}</inlineCode>{` object that contains properties that would be associated with a house:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const house = {
  bedrooms: 3,
  bathrooms: 2,
  parking: true,
  swimmingPool: {
    width: 10,
    length: 20,
    depth: 5.5
  }
};
`}</code></pre>
    <p>{`Let's say we want to access the value of `}<inlineCode parentName="p">{`width`}</inlineCode>{` from the `}<inlineCode parentName="p">{`swimmingPool`}</inlineCode>{` object of `}<inlineCode parentName="p">{`house`}</inlineCode>{`.`}</p>
    <p>{`Because you are a considerate programmer, you would likely check that the `}<inlineCode parentName="p">{`swimmingPool`}</inlineCode>{` property exists on `}<inlineCode parentName="p">{`house`}</inlineCode>{` and the `}<inlineCode parentName="p">{`width`}</inlineCode>{` property exists on `}<inlineCode parentName="p">{`swimmingPool`}</inlineCode>{` before accessing the value of `}<inlineCode parentName="p">{`width`}</inlineCode>{`.`}</p>
    <p>{`Without optional chaining, you might write something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Without optional chaining
const poolWidth = house.swimmingPool && house.swimmingPool.width;
`}</code></pre>
    <p>{`But with optional chaining, we can simplify this expression by writing:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// With optional chaining
const poolWidth = house?.swimmingPool?.width;
`}</code></pre>
    <p>{`As you can see, the `}<inlineCode parentName="p">{`?`}</inlineCode>{` allows us to read an object value without having to validate its existence first.`}</p>
    <p>{`If we were to use the `}<inlineCode parentName="p">{`?`}</inlineCode>{` operator to access an object value that does not exist, the `}<inlineCode parentName="p">{`?`}</inlineCode>{` expression will return `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`. Whereas using the standard `}<inlineCode parentName="p">{`.`}</inlineCode>{` operator would throw an error:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const apartment = {
  bedrooms: 1,
  bathrooms: 1,
  parking: true,
};
`}</code></pre>
    <p>{`This would throw an error as swimmingPool does not exist 👎...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// throws error ↓
const const poolWidth = apartment.swimmingPool.width; // Error!
`}</code></pre>
    <p>{`This would short-circuit and return `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` 👍...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// undefined ↓
const const poolWidth = apartment?.swimmingPool?.width;
`}</code></pre>
    <p>{`Pretty neat, huh?`}</p>
    <p>{`At the time of writing, optional chaining has not yet made it into the core of the language (although it will likely be included in ES2020!). Therefore, using optional chaining in your codebase at this time will require a build process for transpiling or polyfilling.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;