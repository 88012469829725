import React from "react";
import Link from "next/link";

import { ButtonProps } from "./types";
import { StyledButton, StyledLink } from "./styled";

const Button: React.FC<ButtonProps> = ({
  href,
  onClick = () => {},
  text,
  variant = "PRIMARY",
  newWindow = false,
}) => {
  if (href) {
    return (
      <Link href={href} passHref>
        <StyledLink
          variant={variant}
          href={href}
          target={newWindow ? "_blank" : ""}
          rel="nonopener"
        >
          <span>{text}</span>
        </StyledLink>
      </Link>
    );
  }

  return (
    <StyledButton variant={variant} onClick={onClick}>
      <span>{text}</span>
    </StyledButton>
  );
};

export default Button;
