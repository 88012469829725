import React from "react";
import { motion } from "framer-motion";

const AnimateIn: React.FC = ({ children }) => (
  <motion.div
    initial="initial"
    animate="enter"
    exit="exit"
    transition={{ duration: 0.2 }}
    variants={{
      initial: { y: 20, opacity: 0 },
      enter: { y: 0, opacity: 1 },
      exit: { y: 20, opacity: 0 },
    }}
  >
    {children}
  </motion.div>
);

export default AnimateIn;
