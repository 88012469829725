module.exports = {
  theme: {
    fontWeight: {
      normal: 400,
      semiBold: 500,
      bold: 700,
      extraBold: 900,
    },
    fontFamily: {
      sans: ["Rubik", "Helvetica", "Arial"],
      serif: ["Rubik", "Helvetica", "Arial"],
      title: ["AvertaPE", "Helvetica", "Arial"],
      mono: ["Source Code Pro", "mono space"],
    },
    screens: {
      xs: "375px",
      sm: "640px",
      md: "768px",
      lg: "920px",
      xl: "1200px",
    },
    extend: {
      textColor: {
        primary: "#262729",
        orange: '#F59E0B'
      },
      colors: {
        black: "#151515",
        primary: "#F59E0B",
        secondary: "#AE1446",
        grey: "#3D3D3D",
        orange: "#F59E0B",
      },
      fontSize: {
        "2.5xl": "1.65rem",
        "4.5xl": "2.6rem",
      },
      lineHeight: {
        "extra-relaxed": "1.8",
      },
    },
  },
  variants: {},
  plugins: [],
  purge: false,
};
