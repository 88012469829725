import styled, { css } from "styled-components";
import tw from "twin.macro";

import { ButtonProps } from "./types";

const baseStyles = css`
  ${tw`relative text-center font-bold font-title py-2 px-4 inline-block rounded overflow-hidden font-extraBold uppercase text-sm tracking-wide md:px-5`}

  &::before {
    ${tw`rounded`}
    content: "";
    position: absolute;
    border: 2px solid #F59E0B;
    opacity: 0;
    top: 0;
    left: 0;
    transform: scale(1.1);
    width: 100%;
    height: 100%;
    transition: all 200ms ease-in-out;
  }

  &:hover {
    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }

  span {
    ${tw`relative`}
  }
`;

const primaryStyles = css`
  ${tw`bg-black text-white`}
`;

const secondaryStyles = css`
  ${tw`bg-secondary`}
`;

const blackStyles = css`
  ${tw`bg-black`}
  &::before {
    border-color: #989898;
  }
`;

export const StyledLink = styled.a<{ variant: ButtonProps["variant"] }>`
  ${baseStyles};
  ${({ variant }) => variant === "PRIMARY" && primaryStyles};
  ${({ variant }) => variant === "SECONDARY" && secondaryStyles};
  ${({ variant }) => variant === "BLACK" && blackStyles};
`;

export const StyledButton = styled.button<{ variant: ButtonProps["variant"] }>`
  ${baseStyles}
  ${({ variant }) => variant === "PRIMARY" && primaryStyles};
  ${({ variant }) => variant === "SECONDARY" && secondaryStyles};
  ${({ variant }) => variant === "BLACK" && blackStyles};
`;
