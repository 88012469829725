
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer, Notice, Image, Directory } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "How to create a spacing system using Styled Components",
  subtitle: "In this article we are going dive into spacing systems, we will explore what a spacing system is and how it can help you design with confidence and consistency.",
  categories: "Design, CSS",
  date: "2020-04-25",
  slug: "how-to-create-a-spacing-system-with-styled-components"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In this article we are going dive into spacing systems, we will explore what a spacing system is and how it can help you design with confidence and consistency. We will then discuss how to implement a spacing system using GatsbyJS and Styled Components.`}</p>
    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`As we will be cloning a starter project from Github, basic git and command line knowledge is recommended`}</li>
      <li parentName="ul">{`You will need `}<a target="_blank" href="https://nodejs.org/en/">{`node`}</a>{` and `}<a target="_blank" href="https://www.npmjs.com/">{`npm`}</a>{` installed on your machine to run the app and install the any dependencies`}</li>
      <li parentName="ul">{`Basic understanding of React is recommended but not required`}</li>
    </ul>
    <Spacer mdxType="Spacer" />
    <h2>{`What the heck is a spacing system, anyway?`}</h2>
    <p>{`A spacing system is simply a set of predefined spacing values that your designs always adhere to. Not only does a spacing system help you design faster, they can also speed up your development process and provide confidence when positioning elements.`}</p>
    <p>{`Web design is hard. If you are anything like me, then you often look at a beautifully designed website and struggle to pin down actually what aspects make it look good. Compared to development where there is often a clear right wrong approach to a problem, I find the subjective nature of design a real challenge. Having a consistent spacing system in place for approaching a design helps a lot.`}</p>
    <Spacer mdxType="Spacer" />
    <h2>{`Where can I find a spacing system?`}</h2>
    <p>{`You might be wondering where to find a spacing system of your own. You can’t just simply pluck some random values from thin air, right?`}</p>
    <p>{`For my spacing values, I like to “steal like an artist” and lean on the skills and experience of other designers that are more qualified than I am. My spacing system comes from a fantastic booked called `}<a href="https://refactoringui.com/" target="_blank">{`Refactoring UI`}</a>{` by Steve Schoger and Adam Wathan. Steve is a brilliant designer, in Refactoring UI he shares this spacing scale that I reach for in most of my projects:`}</p>
    <Image src="/images/blog/spacing-system.svg" alt="Spacing scale form Refactoring UI" unsized mdxType="Image" />
    <p>{`Using this scale results in consistent spacing across all screen sizes as it can be calculated by using a base font size. For example, you may use a base font size of 15px on mobile which increases to 16px at larger screen sizes, using a scale like this will adjust the values accordingly.`}</p>
    <Spacer mdxType="Spacer" />
    <h2>{`Setting up the GatsbyJS project`}</h2>
    <p>{`To create our spacing system we are going to use a basic starter project that includes `}<inlineCode parentName="p">{`gatsby`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` and some bare-bone components.`}</p>
    <p>{`To get started, clone the repository found `}<a href="https://github.com/lukejohnbrown/styled-components-spacing-system" target="_blank">{`here`}</a>{` to your machine:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://github.com/lukejohnbrown/styled-components-spacing-system.git
`}</code></pre>
    <p>{`Once complete, you will need to install the project dependencies by running `}<inlineCode parentName="p">{`npm install`}</inlineCode>{` from within the project directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd styled-components-spacing-system
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <p>{`As you now (hopefully 🤞) have all of the project dependencies installed, you can start the app by running `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` in your terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm start
`}</code></pre>
    <p>{`If all goes well, you should see the application running in your web browser at `}<inlineCode parentName="p">{`localhost:8000`}</inlineCode>{`:`}</p>
    <Image src="/images/blog/recipe-card-start.png" alt="App running in browser displaying recipe card with no spacing" mdxType="Image" />
    <p>{`Our starter project contains a simple recipe card that is looking rather dull. To jazz it up a bit we are going to implement our spacing system and give the recipe card some much-needed spacing.`}</p>
    <Spacer mdxType="Spacer" />
    <h2>{`Creating the Theme Using Styled Component's ThemeProvider`}</h2>
    <p>{`One of my favorite features of `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` is the ability to provide a theme for your project, which can then be referenced in all of your styled components.`}</p>
    <p>{`To implement our theme, open the project in your code editor of choice and navigate to the `}<inlineCode parentName="p">{`Layout`}</inlineCode>{` component, which can be found at `}<inlineCode parentName="p">{`src/components/Layout.js`}</inlineCode>{`.`}</p>
    <Directory path="src/components/Layout.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle\`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    font-family: 'Lato', serif;
  }
\`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <main>{children}</main>
    </>
  )
};

export default Layout;
`}</code></pre>
    <p>{`This is our base layout component that is used to wrap all pages to provide app-wide structure and styling.`}</p>
    <p>{`Within this `}<inlineCode parentName="p">{`Layout`}</inlineCode>{` component, we are:`}</p>
    <ol>
      <li parentName="ol">{`Applying basic global reset styles using `}<inlineCode parentName="li">{`createGlobalStyle`}</inlineCode>{` from `}<inlineCode parentName="li">{`styled-components`}</inlineCode>{`, these styles are applied to all child elements that make use of this `}<inlineCode parentName="li">{`Layout`}</inlineCode>{` wrapper.`}</li>
      <li parentName="ol">{`Returning any provided child components wrapped in a `}<inlineCode parentName="li">{`<main />`}</inlineCode>{` tag. If you are not familiar with this common React pattern, it may make more sense when we dive into the other components.`}</li>
    </ol>
    <p>{`Using the `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` library, we can now create a theme that will import the spacing values we discussed earlier.`}</p>
    <p>{`Let's start by creating our theme object by adding the following to the top of the `}<inlineCode parentName="p">{`Layout`}</inlineCode>{` component:`}</p>
    <Directory path="src/components/Layout.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`...

const theme = {
  space: [
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "8rem",
    "12rem",
    "16rem",
    "24rem",
  ]
}

...
`}</code></pre>
    <p>{`We now need to make this theme available to any children components by using a `}<inlineCode parentName="p">{`Provider`}</inlineCode>{`. To do this, we first need to extend our imports from `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` to  include `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{`:`}</p>
    <Directory path="src/components/Layout.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { createGlobalStyle, ThemeProvider } from "styled-components";
...
`}</code></pre>
    <p>{`We can then pass our theme to the `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` as a prop and include it in our exported `}<inlineCode parentName="p">{`JSX`}</inlineCode>{`.`}</p>
    <p>{`Your `}<inlineCode parentName="p">{`Layout.js`}</inlineCode>{` file should now look like this:`}</p>
    <Directory path="src/components/Layout.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

const GlobalStyle = createGlobalStyle \`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    font-family: 'Lato', serif;
  }
\`;


const theme = {
  space: [
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "8rem",
    "12rem",
    "16rem",
    "24rem",
  ]
}

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
    </ThemeProvider>
  )
};

export default Layout;
`}</code></pre>
    <p>{`You can include any values within this theme and all subsequent components that use `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` will have access to those values.`}</p>
    <Notice title="How are our theme values being made available to other components?" mdxType="Notice">
  Styled Components uses React Context to expose theme values and make them available to child components (which is why we wrapped our existing components with the ThemeProvider).
  <br /><br />
  You can read more about React Context <a href='https://reactjs.org/docs/context.html'>here</a>.
    </Notice>
    <Spacer mdxType="Spacer" />
    <h2>{`Using the theme to style our recipe card component`}</h2>
    <p>{`Now that we have set up our theme, it is time to start adding some much-needed spacing to our recipe card component.`}</p>
    <p>{`In your code editor, open the `}<inlineCode parentName="p">{`RecipeCard.js`}</inlineCode>{` file, which can be found at `}<inlineCode parentName="p">{`src/components/RecipeCard.js`}</inlineCode>{`:`}</p>
    <Directory path="src/components/RecipeCard.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import styled from "styled-components";

import Nutrition from "./Nutrition";
import Ingredients from "./Ingredients";

import cakeImage from "../images/cake.jpg";

const RecipeCardWrapper = styled.div\`
  box-shadow: 0px 4px 20px rgba(222, 222, 222, 0.25);
  border: 1px solid #F8F8F8;
  border-radius: 15px;
\`;

const RecipeCardHeader = styled.div\`
  /* We need some spacing styles here */
\`;

const RecipeCard = () => {
  return (
    <RecipeCardWrapper>
      <img src={cakeImage} alt="Victoria Sponge Cake" />
      <RecipeCardHeader>
        <h3>Victoria Sponge Cake</h3>
        <p>Perfect for all occasions, the Victoria Sponge cake is a family favorite.</p>
      </RecipeCardHeader>

      <Nutrition />
      <Ingredients />
    </RecipeCardWrapper>
  )
};

export default RecipeCard;
`}</code></pre>
    <p>{`There is a lot happening in this file, so let's explore it step by step:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`After importing the 3rd party dependencies at the top of the file we are importing the `}<inlineCode parentName="p">{`Nutrition`}</inlineCode>{` and `}<inlineCode parentName="p">{`Ingredients`}</inlineCode>{` components that are used to make up the body of the recipe card (more on this later).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After the imports, we are creating two styled components for our recipe card that includes some basic `}<inlineCode parentName="p">{`CSS`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We are then exporting the `}<inlineCode parentName="p">{`RecipeCard`}</inlineCode>{` component, which includes an image of a delicious cake and the `}<inlineCode parentName="p">{`Nutrition`}</inlineCode>{` and `}<inlineCode parentName="p">{`Ingredients`}</inlineCode>{` components we imported at the top of the file.`}</p>
      </li>
    </ol>
    <Spacer mdxType="Spacer" />
    <h3>{`Applying our first padding styles`}</h3>
    <p>{`Let's start by applying some spacing to the `}<inlineCode parentName="p">{`RecipeCardWrapper`}</inlineCode>{` styled component. The `}<inlineCode parentName="p">{`RecipeCardHeader`}</inlineCode>{` styled component that is wrapping the title and subtitle could with some padding:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`...

const RecipeCardHeader = styled.div\`
  padding: \${(props) => props.theme.space[4]};

  p {
    margin-top: \${props => props.theme.space[1]};
  }
\`;

...
`}</code></pre>
    <p>{`This is made possible by using template strings as they allow us to run JavaScript within the string (note the backticks wrapping the CSS). We are calling a function that receives `}<inlineCode parentName="p">{`props`}</inlineCode>{` as the first argument, `}<inlineCode parentName="p">{`props`}</inlineCode>{` is an object containing our theme so we can access the `}<inlineCode parentName="p">{`space`}</inlineCode>{` object and return the desired value as padding.`}</p>
    <p>{`If you now check out the app in your browser you will notice the additional spacing around the header of our recipe card - it is starting to look better already:`}</p>
    <Image src="/images/blog/recipe-card-with-header-padding.png" alt="Recipe card with header padding" mdxType="Image" />
    <Spacer mdxType="Spacer" />
    <Spacer mdxType="Spacer" />
    <h3>{`Styling the recipe card nutritional stats`}</h3>
    <p>{`Next up, we are going to style the sad-looking nutrition section of our recipe card. If you open the `}<inlineCode parentName="p">{`Nutrition.js`}</inlineCode>{` file in your code editor you will find two styled components, the first being the `}<inlineCode parentName="p">{`NutritionWrapper`}</inlineCode>{` which is used to wrap our entire component and the second called `}<inlineCode parentName="p">{`NutritionItem`}</inlineCode>{` which wraps each nutritional value:`}</p>
    <Directory path="src/components/Nutrition.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import styled from "styled-components";

const NutritionWrapper = styled.div\`
  display: grid;
\`;

const NutritionItem = styled.div\`
  border: 1px solid #eee;
  border-radius: 10px;
  text-align: center;
\`;

const Nutrition = () => (
  <NutritionWrapper>
    <NutritionItem>
      <span>kcal</span>
      <p>558</p>
    </NutritionItem>
    <NutritionItem>
      <span>fat</span>
      <p>28g</p>
    </NutritionItem>
    <NutritionItem>
      <span>saturates</span>
      <p>17g</p>
    </NutritionItem>
    <NutritionItem>
      <span>carbs</span>
      <p>76g</p>
    </NutritionItem>
    <NutritionItem>
      <span>sugars</span>
      <p>57g</p>
    </NutritionItem>
  </NutritionWrapper>
);

export default Nutrition;
`}</code></pre>
    <p>{`If you feel comfortable adding the spacing styles for this component yourself, then please don't hesitate to give it a shot.`}</p>
    <p>{`This is how I would approach the CSS to improve the look of this component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const NutritionWrapper = styled.div\`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: \${props => props.theme.space[2]};
  padding: 0 \${props => props.theme.space[4]};
\`;

const NutritionItem = styled.div\`
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  text-align: center;
  padding: \${props => props.theme.space[2]};
  box-shadow: 0px 2px 5px rgba(218, 218, 218, 0.25);

  span {
    color: #747474;
  }
\`;
`}</code></pre>
    <p>{`By using the values of our spacing system to apply padding and margin, we can be confident that the outcome will look good:`}</p>
    <Image src="/images/blog/recipe-card-nutrition-with-padding.png" alt="Recipe card nutrition items with padding" caption="We can quickly and confidently add spacing to our nutrition blocks" mdxType="Image" />
    <p>{`Last but not least, we can add some spacing to the `}<inlineCode parentName="p">{`Ingredients`}</inlineCode>{` component of our recipe card:`}</p>
    <Directory path="src/components/Ingredients.js" mdxType="Directory" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import styled from "styled-components";

const IngredientsWrapper = styled.div\`
  background: #f8f8f8;
  padding: \${props => props.theme.space[4]};
  margin-top: \${props => props.theme.space[4]};
  border-top: 1px solid #e8e8e8;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  p {
    &:not(:last-child) {
      margin-bottom: \${props => props.theme.space[3]};
    }
  }
\`

const Ingredients = () => (
  <IngredientsWrapper>
    <p><strong>Ingredients</strong></p>
    <p>240g caster sugar</p>
    <p>220g butter</p>
    <p>4 eggs</p>
    <p>200g flour</p>
  </IngredientsWrapper>
);

export default Ingredients;
`}</code></pre>
    <Image src="/images/blog/recipe-card-ingredients-with-spacing.png" alt="Recipe card ingredients with spacing" caption="Our ingredients list now has room to breathe thanks to our spacing system" mdxType="Image" />
    <Spacer mdxType="Spacer" />
    <h2>{`The end result`}</h2>
    <p>{`That about sums it up for all our additional styling, with the help of our spacing system the recipe card now looks a lot less cluttered:`}</p>
    <Image src="/images/blog/recipe-card-complete.png" alt="Recipe card in the browser with correct spacing" mdxType="Image" />
    <p>{`You can now hopefully see how powerful a spacing system can be when used to style your components. Knowing that all your padding and margin values are being sourced from a trusted spacing scale should provide more confidence when applying CSS.`}</p>
    <p>{`Now go forth, and space your components with confidence!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;