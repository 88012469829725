import React from "react";

import Link from "next/link";

import { AnimateIn } from "@/components";
import { YOUTUBE_LINK, TWITTER_LINK } from "@/constants";

import { StyledFooter, StyledNav } from "./styled";

const Footer = () => {
  return (
    <StyledFooter>
      <AnimateIn>
        <StyledNav>
          <ul>
            <li className="font-title">
              <Link href="/writing">Archive</Link>
            </li>
            {/* <li className="font-title">
              <Link href="/bookshelf">Bookshelf</Link>
            </li> */}
            <li className="font-title">
              <a href="https://lukebrown.io" target="_blank" rel="noreferrer">Work</a>
            </li>
            <li className="font-title">
              <Link href="https://lukebrown.io/contact">Contact</Link>
            </li>
            <li className="font-title">
              <Link href="/rss.xml">RSS</Link>
            </li>
          </ul>
        </StyledNav>
        <div className="flex items-end mt-4">
          <p className="text-white mr-4 font-title">© {new Date().getFullYear()} Luke Brown</p>
          <StyledNav>
            <ul className="-mt-4">
              <li className="font-title">
                <a href={TWITTER_LINK}
                  target="_blank"
                  rel="noreferrer">Twitter</a>
              </li>
              <li className="font-title">
                <a href={YOUTUBE_LINK}
                  target="_blank"
                  rel="noreferrer">Youtube</a>
              </li>
            </ul>
          </StyledNav>
        </div>
      </AnimateIn>
    </StyledFooter>
  );
};
export default Footer;
