
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "Exploring ES7's new Object methods",
  subtitle: "As you might have already guessed, Object.values() returns an array of the Object's values (basically the opposite to Object.keys()), whereas Object.entries() returns...",
  slug: "exploring-es7-object-values-object-entries",
  date: "2019-07-22"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`For a while now we have had the static Javascript method `}<em parentName="p">{`Object.keys()`}</em>{`, a method that takes an object and returns the object's keys in an array, like so...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dogs = {
    Rocky: 'Bulldog',
    Jeff: 'Poodle',
    Sausage: 'Dachshund'
}

const dogKeys = Object.keys(dogs);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`["Rocky", "Jeff", "Sausage"] //dogKeys
`}</code></pre>
    <p>{`As you can see, we get back an Array containing `}<inlineCode parentName="p">{`'Rocky'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'Jeff'`}</inlineCode>{` and `}<inlineCode parentName="p">{`'Sausage'`}</inlineCode>{`, the keys of our dog's object.`}</p>
    <p><em parentName="p">{`Object.keys()`}</em>{` has been around for quite some time now so browser compatibility is great, but it is not without its flaws. Thankfully, ES7 brings two new Object methods to the specification to make our lives easier when working with Javascript Objects, these are `}<inlineCode parentName="p">{`Object.values()`}</inlineCode>{` and `}<em parentName="p">{`Object.entries()`}</em>{`.`}</p>
    <p>{`As you might have already guessed, `}<inlineCode parentName="p">{`Object.values()`}</inlineCode>{` returns an array of the Object's `}<strong parentName="p">{`values`}</strong>{` (basically the opposite to `}<em parentName="p">{`Object.keys()`}</em>{`), whereas `}<em parentName="p">{`Object.entries()`}</em>{` returns an array where each array item is an array containing a `}{`[key, value]`}{` pair for the object element.`}</p>
    <p>{`Don't worry, this will make more sense when we look at some examples...`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`Using `}<em parentName="h3">{`Object.values()`}</em></h3>
    <p>{`Here we have an object containing the ingredients for a delicious cake. To get the keys of the object, we can use our old friend `}<em parentName="p">{`Object.keys()`}</em>{`...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const ingredients = {
    eggs: 4,
    lemons: 2,
    sugar: '225g',
    flour: '225g',
    butter: '180g'
}

const ingredientKeys = Object.keys(ingredients);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`["eggs", "lemons", "sugar", "flour", "butter"] // ingredientKeys
`}</code></pre>
    <p>{`Now let's make use of `}<inlineCode parentName="p">{`Object.values()`}</inlineCode>{` to get an array of all of the values within our `}<inlineCode parentName="p">{`ingredients`}</inlineCode>{` object...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const ingredientValues = Object.values(ingredients);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`[4, 2, "225g", "225g", "180g"] //ingredientValues
`}</code></pre>
    <p>{`As you can see, `}<inlineCode parentName="p">{`Object.values()`}</inlineCode>{` has given us an array containing all the values of our ingredients. Simple stuff so far, right? Now let's give `}<em parentName="p">{`Object.entries()`}</em>{` a whirl...`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`Using `}<em parentName="h3">{`Object.entries()`}</em></h3>
    <p>{`When you pass an object to `}<em parentName="p">{`Object.entries()`}</em>{` it returns an array containing every `}{`[key value]`}{` pair of the object's properties.`}</p>
    <p>{`Let's try it on our ingredients object...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const ingredientEntries = Object.entries(ingredients);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`[
    ["eggs", 4],
    ["lemons", 2],
    ["sugar", "225g"],
    ["flour", "225g"],
    ["butter", "180g"]
]
`}</code></pre>
    <p>{`Sweet! (No pun intended), we now have a neat array containing the `}{`[`}<inlineCode parentName="p">{`key`}</inlineCode>{` `}<inlineCode parentName="p">{`value`}</inlineCode>{`]`}{` pairs of all of our ingredients.`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`Where might Object.values() and Object.keys() be useful?`}</h3>
    <p>{`Let's imagine that our recipe object was given to us via a database request and we now want to display the ingredients to our users in an unordered list.`}</p>
    <p>{`Previously, we may have used `}<em parentName="p">{`Object.keys()`}</em>{` to achieve this by looping over each key and appending a new item to the list.`}</p>
    <p>{`Here's is how that might look using `}<em parentName="p">{`Object.keys()`}</em>{`...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const ul = document.querySelector('.ingredients');

Object.keys(ingredients).forEach(ingredient =>
  ul.innerHTML += \`<li>\${ingredients[ingredient]} \${ingredient}</li>\`
)
`}</code></pre>
    <p>{`There is nothing wrong with this solution, but it could certainly be neater. When using `}<em parentName="p">{`Object.keys()`}</em>{` we need to awkwardly fetch the item's value by accessing it via the ingredients object, which doesn't look too pretty.`}</p>
    <p>{`Let's take a look at how `}<em parentName="p">{`Object.entries()`}</em>{` could tidy this up. In this example we are not reaching outside of the loop to grab the `}<inlineCode parentName="p">{`ingredient`}</inlineCode>{` object value, as `}<em parentName="p">{`Object.entries()`}</em>{` gives us both the `}<inlineCode parentName="p">{`key`}</inlineCode>{` and the `}<inlineCode parentName="p">{`value`}</inlineCode>{`...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const ul = document.querySelector('.ingredients');

Object.entries(ingredients).forEach(([key, val]) => {
    ul.innerHTML += \`<li>\${val} \${key}</li>\`;
});
`}</code></pre>
    <p>{`As `}<em parentName="p">{`Object.entries()`}</em>{` returns an array for each object property, we can use ES6 `}<strong parentName="p">{`destructuring`}</strong>{` to tidy things up. In this example we are immediately destructuring the `}<inlineCode parentName="p">{`key`}</inlineCode>{` and `}<inlineCode parentName="p">{`value`}</inlineCode>{` from the array and assigning them each to a variable that we can use in our function.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;