import React from "react";
import Link from "next/link";


const Logo = () => {
  return (
    <Link href="/">
      <img src="/images/logo.svg" alt="Luke Brown's Blog Homepage" className="w-12 " />
    </Link>
  );
};

export default Logo;
