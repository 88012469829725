import styled from "styled-components";
import tw from "twin.macro";

export const StyledCodeWrapper = styled.div`
  ${tw`relative`}
`;

export const StyledLanguage = styled.p`
  ${tw`text-sm`}
  margin: 0;
  position: absolute;
  color: white;
  z-index: 1;
  right: 8px;
  top: 6px;
  font-family: "Source Code Pro", monospace !important;
  color: white;
  text-transform: uppercase;
`;
