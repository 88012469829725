
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "How to test a Material UI Select component using React Testing Library",
  subtitle: "As great as the library is, testing Material UI components can be tricky at times. This is mainly due to how the components are nested within the DOM on render, which can sometimes make it difficult to know...",
  slug: "testing-mui-select",
  date: "2021-10-23"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As great as the library is, testing `}<a href="https://mui.com/" target="_blank" rel="norefferer">{`Material UI`}</a>{` components can be tricky at times. This is mainly due to how the components are nested within the DOM on render, which can sometimes make it difficult to know what elements to target using `}<a href="https://testing-library.com/" target="_blank" rel="norefferer">{`React Testing Library`}</a>{`.`}</p>
    <p>{`One component that often trips me up when writing tests is the `}<a href="https://mui.com/components/selects/#main-content" target="_blank" rel="norefferer">{`Select`}</a>{` component. I often find myself searching through previous test files to find an example of how to target the dropdown and select a menu item. `}</p>
    <p><strong parentName="p">{`Here is the valuable code snippet that often saves my bacon:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// MySelectComponent.js
<MuiSelect
    ...
    SelectProps={{
        SelectDisplayProps: {
            ['data-testid']: 'select-id',
        },
    }}
>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { render, fireEvent, within } from "@testing-library/react";

// MySelectComponent.test.js
const { findByTestId, getByRole } = render(<MySelectComponent />)
const selectElement = await findByTestId('select-id');
fireEvent.mouseDown(selectElement);

const dropdownList = getByRole('listbox');

fireEvent.click(
  within(dropdownList).getByText('Menu item text')
);
`}</code></pre>
    <p>{`At this stage, you may want to take this snippet and run with it (I know I would). But if you do find yourself thirsty for some extra knowledge, I'm here to break down the 'why' for you...`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Firstly, we add a `}<inlineCode parentName="p">{`test-id`}</inlineCode>{` attribute to the select element we wish to target within our test. It is important that we add the `}<inlineCode parentName="p">{`data-testid`}</inlineCode>{` attribute to the correct component as Material UI nests the Select in a few wrapping elements:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`<MuiSelect
    ...
    SelectProps={{
        SelectDisplayProps: {
            ['data-testid']: 'select-id',
        },
    }}
>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After adding the attribute to the correct DOM element, we can target it by using the `}<inlineCode parentName="p">{`findByTestId`}</inlineCode>{` selector within our test. React Testing Library's `}<inlineCode parentName="p">{`findByTestId`}</inlineCode>{` selector returns a promise, which allows us to wait for an element to be available within the DOM. We then call the `}<inlineCode parentName="p">{`mouseDown`}</inlineCode>{` event on the Select input to open the dropdown menu.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`const { findByTestId, getByRole } = render(<MySelectComponent />)
const selectElement = await findByTestId('select-id');
fireEvent.mouseDown(selectElement);
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Clicking the select renders a Material UI dropdown menu in the DOM. The dropdown menu element is a `}<inlineCode parentName="p">{`ul`}</inlineCode>{` with a "role" of `}<inlineCode parentName="p">{`listbox`}</inlineCode>{`. Therefore, we can target the list element by using React Testing Library's `}<inlineCode parentName="p">{`getByRole`}</inlineCode>{` selector. `}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`const dropdownList = getByRole('listbox');
`}</code></pre>
        <p parentName="li"><strong parentName="p">{`FYI:`}</strong>{` This could potentially be an unreliable selector if you have multiple elements with a role of `}<inlineCode parentName="p">{`listbox`}</inlineCode>{` within the DOM, so you may need to get more specific with your selector here.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Finally, we can target the dropdown item by its label text and click on the element to change the input value. Note that we are using the `}<inlineCode parentName="p">{`within`}</inlineCode>{` method from React Testing Library to narrow the scope of our selector. What we are essentially saying here is `}<em parentName="p">{`"select an element with the text 'Menu item text' from within my dropdown list"`}</em>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`fireEvent.click(
  within(dropdownList).getByText('Menu item text')
);
`}</code></pre>
        <p parentName="li">{`Annnndddd you're done! Now that you know how to confidently test a Material UI Select component you can continue to make the web a better place with smashing test coverage.`}</p>
      </li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;