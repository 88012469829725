import styled from "styled-components";
import tw from "twin.macro";

import { theme } from "@/theme";


export const StyledNav = styled.nav`
  ${tw`flex items-center`}
  li {
    list-style: none;
    &:not(:last-child) {
      ${tw`pr-4 xs:pr-6 md:pr-8`}
    }

    a,
    button {
      ${tw`text-white tracking-wide`}
      outline: none;
      &:hover {
        color: ${theme.extend.colors.orange};
      }
    }
  }
`;

export const StyledWrapper = styled.div`
  ${tw`px-5 w-full py-6 leading-4 flex justify-between items-center sm:px-10`}
`;



