/* eslint-disable react/no-array-index-key */
import React from "react";

import Highlight, { defaultProps, Language } from "prism-react-renderer";

import { CodeProps } from "./types";
import { StyledCodeWrapper, StyledLanguage } from "./styled";

const Code: React.FC<CodeProps> = ({ children, className, result }) => {
  const language = className ? className.replace(/language-/, "") : "html";

  return (
    <div className="mt-4">
      <Highlight
        {...defaultProps}
        code={children as string}
        language={language as Language}
        theme={undefined}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <StyledCodeWrapper>
            <StyledLanguage>{language}</StyledLanguage>
            <pre
              className={result ? `${className} result` : className}
              style={{ ...style }}
            >
              {tokens.map((line, i) => {
                if (
                  line.every((token) => token.empty) &&
                  i + 1 === tokens.length
                ) {
                  return <span key={i} />;
                }
                return (
                  <div key={i} {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                );
              })}
            </pre>
          </StyledCodeWrapper>
        )}
      </Highlight>
    </div>
  );
};

export default Code;
