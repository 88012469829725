import React from "react";

const Quote: React.FC = ({ children }) => (
  <div className="mt-6 pl-4">
    <blockquote className="leading-relaxed mt-4 font-bold italic">
      {children}
    </blockquote>
  </div>
);

export default Quote;
