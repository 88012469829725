import styled from "styled-components";
import tw from "twin.macro";

export const StyledFooter = styled.footer`
  ${tw`relative px-5 pb-6 mt-12 w-full leading-4 sm:px-10 md:mt-16 z-20`}
`;

export const StyledNav = styled.nav`
  ul {
    ${tw`flex flex-wrap`}
    li {
      ${tw`pt-3 text-white `}
      &:not(:last-child) {
        ${tw`mr-4 sm:mr-4`}
      }

      a {
        ${tw`underline hover:no-underline hover:text-white`}
      }
    }
  }
`;
