
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "Managing State with React Hooks",
  subtitle: "Before the introduction of React Hooks, there was no way of using state in a functional component. But we can now use the useState Hook to apply local state to our functional components...",
  categories: "Javascript, React, Hooks",
  date: "2019-07-23",
  slug: "managing-state-with-react-hooks"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Before the introduction of React Hooks, there was no way of using state in a functional component. But we can now use the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` Hook to apply local state to our functional components.`}</p>
    <p>{`React Hooks have been well received by the community, and you will likely notice that many popular libraries already offer a solution that uses Hooks. You may also notice that all Hooks (should) follow the same naming convention, which is the word `}<em parentName="p">{`use`}</em>{` followed by the data or functionality being provided by the Hook.`}</p>
    <p>{`Thats enough chit-chat, let's start using our first Hook (have I said the word Hooks enough yet?). Let's start by exploring how can set state in our functional component.`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`Setting State with React Hooks`}</h3>
    <p>{`First, we need to import `}<inlineCode parentName="p">{`useState`}</inlineCode>{` from the React library to use in our component...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { useState } from "react";
`}</code></pre>
    <p>{`We can then create our functional component and call `}<inlineCode parentName="p">{`useState`}</inlineCode>{` before our return statement...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from "react";


const ShoppingList = () => {
  const [shoppingList, setShoppingList] = useState(["Bread", "Milk", "Eggs"]);
  return (
    <>
      <ul>
        {shoppingList.map(listItem => (
          <li>{listItem}</li>
        ))}
      </ul>
    </>
  );
}

export default ShoppingList;
`}</code></pre>
    <p>{`As you can see, the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` method returns two items, which are -`}</p>
    <ol>
      <li parentName="ol">{`The `}<em parentName="li">{`current`}</em>{` value of the state item, which we are storing as a variable named `}<inlineCode parentName="li">{`shoppingList`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`A function for updating the state item, which we are storing as a variable named `}<inlineCode parentName="li">{`setShoppingList`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`useState`}</inlineCode>{` Hook accepts a single argument which is the initial value of the state item. We are setting our initial value to an array containing shopping list items.`}</p>
    <p>{`Don't worry if you don't recognize the funky square brackets on line `}<inlineCode parentName="p">{`4`}</inlineCode>{`, we are using `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment"
      }}>{`ES6 destructing`}</a>{` here to grab the array items returned by `}<inlineCode parentName="p">{`useState`}</inlineCode>{` and assigning them to variables.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`setShoppingList`}</inlineCode>{` method returned by the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` Hook can be used to update our `}<inlineCode parentName="p">{`shoppingList`}</inlineCode>{` state. In this example we have a button that simply adds "Bread" to our shopping list....`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from "react";

const ShoppingList = () => {
  const [shoppingList, setShoppingList] = useState(["Bread", "Milk", "Eggs"]);
  return (
    <>
      <ul>
        {shoppingList.map(listItem => (
          <li>{listItem}</li>
        ))}
      </ul>

      <button onClick={() => setShoppingList([
        ...shoppingList,
        "Bread"
      ])}>Add item</button>
    </>
  );
}

export default ShoppingList;
`}</code></pre>
    <p>{`Whatever value we pass to `}<inlineCode parentName="p">{`setShoppingList`}</inlineCode>{` will completely overwrite our `}<inlineCode parentName="p">{`shoppingList`}</inlineCode>{` state, so it is important that we replicate the existing shopping list items before appending a new item. To achieve this we are using `}<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax" target="_blank">{`spread syntax`}</a>{` to "spread" all the existing values of our shopping list into our new array.`}</p>
    <p>{`Great, we have successfully added state to our functional component, but how do we replicate lifecycle methods?`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`Replicating Lifecycle Methods Using the `}<em parentName="h3">{`useEffect`}</em>{` Hook`}</h3>
    <p>{`With React classes, we use "lifecycle" methods such as `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and `}<inlineCode parentName="p">{`componentWillMount`}</inlineCode>{` to run some code when an event occurs in our component (these are known as `}<em parentName="p">{`side effects`}</em>{`). Although we don't have access to these lifecycle methods within functional components, we can use the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook to replicate their functionality.`}</p>
    <p><strong parentName="p">{`By default the `}<inlineCode parentName="strong">{`useEffect`}</inlineCode>{` hook runs after every render of the component, this includes the initial render and any re-rendering caused by a change in state.`}</strong></p>
    <p>{`Let's take a look at an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState, useEffect } from "react";

const ShoppingList = () => {
  const [shoppingList, setShoppingList] = useState(["Bread", "Milk", "Eggs"]);
  const [newItem, setNewItem] = useState('');

  useEffect(() => {
    if (shoppingList.includes(newItem)) {
      alert("You already have that item in your shopping list!");
    }
  });

  return (
    <>
      <ul>
        {shoppingList.map(listItem => (
          <li>{listItem}</li>
        ))}
      </ul>

      <input value={newItem} onChange={(e) => setNewItem(e.target.value)} />

      <button onClick={() => setShoppingList([
        ...shoppingList,
        newItem
      ])}>Add item</button>
    </>
  );
}

export default ShoppingList;
`}</code></pre>
    <p>{`We have made our `}<inlineCode parentName="p">{`ShoppingList`}</inlineCode>{` component a bit cleverer by including a new state item called `}<inlineCode parentName="p">{`newItem`}</inlineCode>{` and a text field that updates our `}<inlineCode parentName="p">{`newItem`}</inlineCode>{` state when changed. Also, clicking the button now adds the `}<inlineCode parentName="p">{`newItem`}</inlineCode>{` to our shopping list rather than simply appending "Bread" (how much bread could you possibly need?!).`}</p>
    <p>{`More notably, we are using the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook to display an annoying alert if the user enters an item that already appears in `}<inlineCode parentName="p">{`shoppingList`}</inlineCode>{`.`}</p>
    <p>{`Here is a run-down on what is happening here:`}</p>
    <ol>
      <li parentName="ol">{`Our user types into the text field, firing the `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` event which in turn updates our `}<inlineCode parentName="li">{`newItem`}</inlineCode>{` state`}</li>
      <li parentName="ol">{`As the state of our component has been updated a re-render is performed`}</li>
      <li parentName="ol">{`The code within our `}<inlineCode parentName="li">{`useEffect`}</inlineCode>{` Hook gets called on each render and the check is performed`}</li>
    </ol>
    <p>{`Pretty neat, right? As mentioned, the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook will run after every render of your component, - but what if we `}<em parentName="p">{`only`}</em>{` want it to run after specific items of state are changed?`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`Skipping over Effects to Improve Performance`}</h3>
    <p>{`In our previous example, the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook was running on every render of our component. This isn't really a problem here as we are only dealing with a small amount of effects, but what if had a much larger component?`}</p>
    <p>{`Let's update our shopping list component to include the current time as an item of state, we will need to update the time every second to keep it up to date. This causes a re-render of our component every second, which in turn runs our `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook every second.`}</p>
    <p>{`However, The code inside our `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook is not concerned with `}<inlineCode parentName="p">{`time`}</inlineCode>{`, so we need to tell our Hook to `}<strong parentName="p">{`only`}</strong>{` run when `}<inlineCode parentName="p">{`newItem`}</inlineCode>{` or `}<inlineCode parentName="p">{`shoppingList`}</inlineCode>{` is updated.`}</p>
    <p><strong parentName="p">{`We can tell our `}<inlineCode parentName="strong">{`useEffect`}</inlineCode>{` Hook what items we want it to "listen" to by including them in an array as the second argument of the function call. `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState, useEffect } from "react";

const ShoppingList = () => {
  const [shoppingList, setShoppingList] = useState(["Bread", "Milk", "Eggs"]);
  const [newItem, setNewItem] = useState('');
  const [time, setTime] = useState();

  setInterval(() => {
    const now = new Date();
    setTime(\`\${now.getHours()}:\${now.getMinutes()}:\${now.getSeconds()}\`);
  }, 1000);

  useEffect(() => {
    if (shoppingList.includes(newItem)) {
      alert("You already have that item on your shopping list!");
    }
  }, [newItem, shoppingList]);

  return (
    <>
      <ul>
        {shoppingList.map(listItem => (
          <li>{listItem}</li>
        ))}
      </ul>

      <input value={newItem} onChange={(e) => setNewItem(e.target.value)} />

      <button onClick={() => setShoppingList([
        ...shoppingList,
        newItem
      ])}>Add item</button>

      <p>The time is currently {time}</p>
    </>
  );
}

export default ShoppingList;
`}</code></pre>
    <p>{`As you can see, we have added a new item to state called `}<inlineCode parentName="p">{`time`}</inlineCode>{`. By using `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{` we are able to update the time every second as a neatly formatted string.`}</p>
    <p>{`More importantly, we are passing `}<inlineCode parentName="p">{`[newItem, shoppingList]`}</inlineCode>{` to our `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` Hook in order to tell it when to run. Now when our component triggers a re-render caused by a time update, it doesn't run our code inside `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, which is a great performance gain!`}</p>
    <Spacer mdxType="Spacer" />
    <h3>{`That's all, folks!`}</h3>
    <p>{`Before `}<em parentName="p">{`Hooks`}</em>{` were introduced to React in version 16.8, this was a common occurrence for me when building React components...`}</p>
    <ol>
      <li parentName="ol">{`Create a component as a neat, small functional component`}</li>
      <li parentName="ol"><strong parentName="li">{`*`}{`1 hour later`}{`*`}</strong></li>
      <li parentName="ol">{`Say something along the lines of `}<em parentName="li">{`"Ah, I actually need to use state and/or a lifecycle method in that component I created earlier!"`}</em></li>
      <li parentName="ol">{`Go back and convert the component from a functional component to a class based component`}</li>
    </ol>
    <p>{`I reached a point where I was creating all of my components using classes to avoid this issue, which is certainly not good practice. React Hooks solves this problem by bringing features to functional components that had previously `}<strong parentName="p">{`only`}</strong>{` been available to class based components.`}</p>
    <blockquote>
      <p parentName="blockquote">{`React Hooks let you use more of React's features without reaching for classes`}</p>
    </blockquote>
    <p>{`Not only that, React Hooks also offers a cleaner way of sharing reusable behavior between components without using messier solutions like render props and higher order component.`}</p>
    <p>{`Hopefully this was a helpful lesson on how to manage state using React Hooks. I implore you to start using Hooks in your functional components today, you might even find that you never reach for a class again (not that I have a problem with classes, please dont @ me).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;