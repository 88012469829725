
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer, Notice } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "Top Javascript Array Methods",
  subtitle: "As web developers, we have access to some extremely useful methods for working with arrays in Javascript, many of which didn't exist when I started learning. Knowing and utilizing these methods will give you super powers when working with arrays...",
  categories: "Javascript, Arrays",
  date: "2019-07-20",
  slug: "top-javascript-array-methods"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As web developers, we have access to some extremely useful methods for working with arrays in Javascript, many of which didn't exist when I started learning. Knowing and utilizing these methods will give you super powers when working with arrays.`}</p>
    <Spacer space="6" mdxType="Spacer" />
    <h3>{`Array.map()`}</h3>
    <p>{`When you call `}<inlineCode parentName="p">{`.map()`}</inlineCode>{` on an array, a function is called on each item of that array where you can modify its contents. This produces a new, modified version of the original array that `}<strong parentName="p">{`always contains the same number of items`}</strong>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`.map()`}</inlineCode>{` method is super useful for when you want to modify all the existing items of an array and store the result as a new variable.`}</p>
    <p>{`For example, here we have an array containing dog names...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dogs = ['jeff', 'rocky', 'waggy', 'fluffy'];
`}</code></pre>
    <p>{`Let's say we want to amend each element in the array to add `}<strong parentName="p">{`‘is a good boy’`}</strong>{` to each dog name...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const goodBoys = dogs.map(dog => \`\${dog} is a good boy!\`);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`["jeff is a good boy!", "rocky is a good boy!", "waggy is a good boy!", "fluffy is a good boy!"]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`.map()`}</inlineCode>{` method has constructed a new array that is simply a modified version of our existing array!`}</p>
    <p>{`Here we have an array of objects that contain the name and age of our dogs in human years. Let's use `}<inlineCode parentName="p">{`.map()`}</inlineCode>{` to construct a new array that also includes the age of each dog in dog years...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dogsWithHumanAge = [
    {
        name: 'Jeff',
        humanAge: 2
    },
    {
        name: 'Rocky',
        humanAge: 10
    }
];


const dogsWithDogAge = dogsWithHumanAge.map(dogObject => {
    return {
        ...dogObject,
        dogAge: dogObject.humanAge * 7
    }
});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`[{ name: 'Jeff', humanAge: 2, dogAge: 14 }, { name: 'Rocky', humanAge: 2, dogAge: 70 }]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`.map()`}</inlineCode>{` is extremely useful for a wide range of scenarios and is easily my most used reached for array method.`}</p>
    <Notice title="When should you use the Array.map() method?" mdxType="Notice">When you want to modify the contents of an existing array and store the result as a new variable</Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Array.filter()`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`.filter()`}</inlineCode>{` method is used when you need to get items of an array `}<strong parentName="p">{`conditionally`}</strong>{`. `}<inlineCode parentName="p">{`Array.filter()`}</inlineCode>{` calls a function on each array item to test that the current item fits the criteria, only if the test returns a `}<inlineCode parentName="p">{`truthy`}</inlineCode>{` value is the item included in the newly constructed array.
The `}<inlineCode parentName="p">{`.filter()`}</inlineCode>{` method always returns a new array, if none of the array items pass the test an empty array is returned.`}</p>
    <p>{`Let’s look at an example...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dogs = [
    {
        name: 'Jeff',
        breed: 'German Shepherd',
        goodBoyRating: 10
    },
    {
        name: 'Rocky',
        breed: 'Beagle',
        goodBoyRating: 3
    },
    {
        name: 'Waggy',
        breed: 'Poodle',
        goodBoyRating: 4
    },
    {
        name: 'Fluffy',
        breed: 'Pug',
        goodBoyRating: 7
    }
]
`}</code></pre>
    <p>{`Here we have an array of objects, each object includes a dog name, their breed and their good boy rating (obviously all dogs are good boys but for the purpose of this example lets pretend they are not!)...`}</p>
    <p>{`Now we want to filter our dogs into ‘good boys’ and ‘bad boys’ based on their good boy rating...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const goodBoys = dogs.filter(dog => dog.goodBoyRating >= 5);

const badBoys = dogs.filter(dog => dog.goodBoyRating < 5);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`// goodBoys
[{name: "Jeff", breed: "German Shepherd", goodBoyRating: 10}, {name: "Fluffy", breed: "Pug", goodBoyRating: 7}]

// badBoys
[{name: "Rocky", breed: "Beagle", goodBoyRating: 3}, {name: "Waggy", breed: "Poodle", goodBoyRating: 4}]
`}</code></pre>
    <p>{`Each item of the array is checked to see if it fits the criteria, if it passes the test it is returned within the new array - neat.`}</p>
    <Notice title="When should you use the Array.filter() method?" mdxType="Notice">
    When you want to remove all items of an array that do not fit a criteria
    </Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Array.reduce()`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`.reduce()`}</inlineCode>{` array function may take a little more time to get to grips with. Put simply, calling `}<inlineCode parentName="p">{`.reduce()`}</inlineCode>{` on an array `}<em parentName="p">{`reduces`}</em>{` it down to a single value after executing a function on each item of the array.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`.reduce()`}</inlineCode>{` method takes a callback function as its first parameter and an optional initial value as its second parameter., if an initial value is not supplied the first array value is used. The callback function provides an `}<inlineCode parentName="p">{`accumulator`}</inlineCode>{` and `}<inlineCode parentName="p">{`currentValue`}</inlineCode>{` parameter used to perform the reduce calculation.`}</p>
    <p>{`Don't worry if this doesn't make sense just yet, let’s take a look at a really simple example...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const numbers = [10, 43, 94, 12, 77];
const total = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`236 // total
`}</code></pre>
    <p>{`In this example, we start at 0 and continue to add each value of the array to a running total. You can think of the accumulator parameter as the current total value and the currentValue as the current array item being looked at.`}</p>
    <p>{`Now let’s explore a slightly more complicated example. Here we have an array of dogs, let's say we want to 'reduce' this down to a single object containing the names and ages in separate arrays...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dogs = [
    {
        name: 'Jeff',
        age: 12
    },
    {
        name: 'Rocky',
        age: 2
    },
    {
        name: 'Waggy',
        age: 10
    },
    {
        name: 'Fluffy',
        age: 6
    }
];

const averageAge = dogs.reduce((acc, dog) => {
  acc.names.push(dog.name);
  acc.ages.push(dog.age);
    return acc;
return acc;
}, {
    names: [],
    ages: []
});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{` {
     names: ["Jeff", "Rocky", "Waggy", "Fluffy"]
     ages: [12, 2, 10, 6]
 }
`}</code></pre>
    <p>{`As you can (hopefully) see, once you get familiar with `}<inlineCode parentName="p">{`.reduce()`}</inlineCode>{` it can be a very powerful tool in your JavaScript toolbox.`}</p>
    <Notice title="When should you use the Array.reduce() method?" mdxType="Notice">
    When you want to convert an array down to a single value by manipulating its items
    </Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Array.forEach()`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`.forEach()`}</inlineCode>{` method loops over an array and executes a function on each item. The first parameter of `}<inlineCode parentName="p">{`.forEach()`}</inlineCode>{` is a callback function that includes the current value and index  of the loop. Let’s take a look at an example...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dogs = [
    {
        name: 'Jeff',
        age: 4
    },
    {
        name: 'Rocky',
        age: 2
    },
    {
        name: 'Waggy',
        age: 10
    },
    {
        name: 'Fluffy',
        age: 6
    }
];

dogs.forEach(dog => {
    console.log(\`\${dog.name} is \${dog.age} year[s] old!\`);
});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`Jeff is 4 year[s] old!
Rocky is 4 year[s] old!
Waggy is 4 year[s] old!
Fluffy is 4 year[s] old!
`}</code></pre>
    <p>{`You might notice that the `}<inlineCode parentName="p">{`.forEach()`}</inlineCode>{` method is unlike many other array methods (`}<inlineCode parentName="p">{`.map()`}</inlineCode>{`, `}<inlineCode parentName="p">{`.filter()`}</inlineCode>{` etc.) as it does not return a new array `}<em parentName="p">{`or`}</em>{` manipulate the array's items.`}</p>
    <Notice title="When should you use the Array.forEach() method?" mdxType="Notice">
    When you want to simply loop over each item of any array without constructing a new array
    </Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Array.find()`}</h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`.find()`}</inlineCode>{` method to return the first element in an array that passes a given test. The `}<inlineCode parentName="p">{`.find()`}</inlineCode>{` method will pass each element of the array through it’s callback function until it finds an element that meets the criteria. If no elements are found, undefined is returned.`}</p>
    <p>{`Lets explore a quick example. Let’s use the same array of dogs we used in the previous example, but we will use the `}<inlineCode parentName="p">{`.find()`}</inlineCode>{` method to return the first dog older than 10...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const oldDog = dogs.find(dog => dog.age >= 10);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`{ age: 10, name: "Waggy" } // Oldest dog
`}</code></pre>
    <Notice title="When should you use the Array.find() method?" mdxType="Notice">
    When you need to get the first item of an array that passes an explicitly defined test
    </Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Array.every()`}</h3>
    <p>{`It may not surprise you that the `}<inlineCode parentName="p">{`.every()`}</inlineCode>{` method checks if `}<strong parentName="p">{`every`}</strong>{` element in the array passes a test. If each element of the array passes the test, `}<inlineCode parentName="p">{`.every()`}</inlineCode>{` will return `}<inlineCode parentName="p">{`true`}</inlineCode>{`, if the method reaches `}<strong parentName="p">{`any`}</strong>{` element that does not pass the test, it returns `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`Here we have an array of people objects, let's use the `}<inlineCode parentName="p">{`.every()`}</inlineCode>{` method to check that all the people in the array are over the age of 18...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const people = [
    {
        name: 'Luke',
        age: 24
    },
    {
        name: 'Jess',
        age: 20
    },
    {
        name: 'Steve',
        age: 43
    },
    {
        name: 'Lucy',
        age: 19
    }
]

const allAdults = people.every(person => person.age > 18);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`true // they are all over 18!
`}</code></pre>
    <Notice title="When should you use the Array.every() method?" mdxType="Notice">
    When you want to confirm that <strong>every</strong> item of an array passes an explicitly defined test
    </Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Array.some()`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`.some()`}</inlineCode>{` method is similar to the `}<inlineCode parentName="p">{`.every()`}</inlineCode>{` method, but instead of returning true if all elements of an array pass the test, it returns true if at least `}<em parentName="p">{`one`}</em>{` element passes the test.`}</p>
    <p>{`If the `}<inlineCode parentName="p">{`.some()`}</inlineCode>{` method finds a successful array element it stops and returns `}<inlineCode parentName="p">{`true`}</inlineCode>{`. However, if `}<inlineCode parentName="p">{`.some()`}</inlineCode>{` reaches the end of the array without success, it returns `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`Lets use our people array again, but this time we will be checking if `}<em parentName="p">{`some`}</em>{` of the people are under the age of 18...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const people = [
    {
        name: 'Luke',
        age: 24
    },
    {
        name: 'Jess',
        age: 20
    },
    {
        name: 'Steve',
        age: 43
    },
    {
        name: 'Lucy',
        age: 19
    }
]

const someChildren = people.some(person => person.age < 18);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "result=true",
        "result": "true"
      }}>{`false // none of our people are under 18!
`}</code></pre>
    <Notice title="When should you use the Array.some() method?" mdxType="Notice">
When you need to confirm that one or more items in an array passes an explicitly defined test
    </Notice>
    <Spacer mdxType="Spacer" />
    <h3>{`Conclusion`}</h3>
    <p>{`As you can (hopefully) see, JavaScript gives us some really neat methods for dealing with arrays. If you harness their power, it is unlikely that you will ever need to reach for an old-school `}<inlineCode parentName="p">{`for`}</inlineCode>{` loop again and  your codebase will likely be much more maintainable - who wouldn't want that?!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;