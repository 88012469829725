
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer, Notice, Quote } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "2021: Beautifully Chaotic",
  subtitle: "There is a good chance that 2021 was one of the best years of my life... have I peaked?",
  categories: "Review, Personal",
  date: "2021-12-30",
  slug: "2021-in-review"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => 
  <Post 
  meta={meta}>
    {children}
  </Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Despite the virus's best efforts to hold us back, this year I started a new job, got married, adopted a puppy and put a baby in the oven (in that order). There is a good chance that 2021 was one of the best years of my life... have I peaked? `}</p>
    <h3>{`New job, who dis?`}</h3>
    <p>{`In March of 2021, I started a new position as a Frontend Developer at `}<a href="https://octopus.energy/" target="_blank">{`Octopus Energy`}</a>{`. My previous role was as a "full stack" web developer and I was yearning for a more frontend focused role, so when a remote position at Octopus Energy appeared on my radar I jumped on it. Things have been great so far, the company is in an exciting position where it is growing crazy quickly and there are some interesting challenges to tackle. `}</p>
    <h3>{`New puppy, who dis?`}</h3>
    <p>{`As if starting a new job wasn't stressful enough, we also adopted a Golden Retriever puppy in March 2021. Whilst he is now my number one boy, raising a puppy is fucking difficult. We did a lot of research before picking up our little guy, so we had an idea of what to expect but it still hit us like a bus as our routines and sleep habits were thrown up in the air. Ultimately, the hard work is worth it. He is now a highlight of my life and being forced out for daily walks has been great for my mental health. `}</p>
    <h3>{`New baby, who dis?`}</h3>
    <p>{`We received a positive test result in June of 2021, thankfully it wasn't from a PCR test but from a pregnancy test. I don't think I have ever been so underprepared for anything in my life, so I had to level up my baby/pregnancy/labour knowledge pretty quickly. We are expecting our little girl in January 2022 and are very excited to meet her (more to come in the 2022 review). `}</p>
    <h3>{`New wife, who dis?`}</h3>
    <p>{`In July of 2021, I was lucky enough to marry my best friend after 10 years of being together. Coronavirus inevitably pushed our date from February to July which did screw up some plans, but it did mean we were married in beautiful sunshine (suck it, covid). It was the perfect day, and we were then lucky enough to jet off to Croatia for a week to celebrate further. `}</p>
    <h2>{`Unrealistic Goals`}</h2>
    <p>{`Going into 2021 I set myself some ambitious goals. In hindsight, they were simply unrealistic. Isn’t there some kind of term for that? The tendency that people have to overestimate their abilities?`}</p>
    <p>{`I set out to read 30 books and managed less than 15. I planned on writing ten articles and ended the year on a pitiful two posts. I wanted to learn some new web technologies like Svelte and Rust, but didn’t really learn many new skills at all. This sounds a little bleak, but I feel great about it. Life got in the way, and I loved every minute of it. I prioritised my family, mental health and new job over everything else and that (obviously) was the right call. `}</p>
    <h2>{`TwentyTwentyTwo`}</h2>
    <p>{`I feel incredibly fortunate for the past 12 months. The entire world is still very unstable with the virus and many of my close friends and family have suffered from it. What would 2021 have looked like if we hadn't spent it in a global pandemic? In a twisted way, it's as though the pandemic forced us to live bigger this year in a valiant effort to not allow it to put our lives on hold. Maybe, everything we have achieved this year is a big "up yours" to COVID-19.`}</p>
    <p>{`2022 is the year I become a father, `}<strong>{`being the best parent that I can be is my one and only priority`}</strong>{`. Anything else is a bonus. `}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;