
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Spacer } from "@/components";
import { Post } from "@/templates";
export const meta = {
  title: "Find your community",
  subtitle: "I recently started a new role as a remote front end developer. The majority of my colleagues are a 4 hour drive away, and some would require a long haul airplane ride to visit. It is truly exciting to productively work with people from all around the world on a daily basis...",
  slug: "find-your-community",
  date: "2021-09-10"
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => <Post meta={meta}>{children}</Post>
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I recently started a new role as a remote front end developer. The majority of my colleagues are a 4 hour drive away, and some would require a long haul airplane ride to visit. Whilst it is exciting to productively work with people from all around the world on a daily basis, working remotely does not come without its difficulties.`}</p>
    <p>{`Personally, for me to feel productive at work it is important that I am surrounded by enthusiastic people that are passionate about development and general tech. I want to have that discussion about “yet another front end framework” and I will be the first to admit that I enjoy a bit of `}<a href="https://www.urbandictionary.com/define.php?term=bikeshedding" rel="norefferer" target="_blank">{`bike shedding`}</a>{`. These casual, “water cooler” interactions are often the most difficult to initiate in a virtual environment.`}</p>
    <p>{`That may sound a little negative, so let me reiterate that I do genuinely love my job. However, I also accept that due to the nature of my remote position it is sometimes going to be difficult to achieve casual “banter” with my workmates. Which is why I have recently been seeking other developer communities online.`}</p>
    <p>{`You may have noticed that there are an abundance of free online developer spaces, usually hosted on Slack or Discord. Your mileage my vary, but I usually find them too noisy to be enjoyable places to spend time. It seems to me that there is a sweet spot for the amount of people within a channel before it becomes a chore to keep up with.`}</p>
    <p>{`With all that in mind, I was seeking a small community of enthusiastic front-end developers to sling some divs with. Thankfully, I found that in the form of the `}<a href="https://www.patreon.com/shoptalkshow" rel="norefferer" target="_blank">{`ShopTalk Show Discord`}</a>{`. The `}<a rel="norefferer" target="_blank" href="https://shoptalkshow.com/">{`ShopTalk Show`}</a>{` is a long running front-end development podcast hosted by `}<a href="https://chriscoyier.net/" rel="norefferer" target="_blank">{`Chris Coyier`}</a>{` and `}<a href="https://daverupert.com/" rel="norefferer" target="_blank">{`Dave Rupert`}</a>{` (It is very good, give it a listen if you haven't already!). The Discord server is currently only available to any listener who supports the show via `}<a href="https://www.patreon.com/shoptalkshow" rel="norefferer" target="_blank">{`Patreon`}</a>{`. It's neat that I get to support a show that has been very valuable to me during my career, whilst getting access to an awesome developer community.`}</p>
    <p>{`Let me give you a run down of what I dig about this Discord server:`}</p>
    <ul>
      <li parentName="ul">{`There are less than 100 active members within the server, making it easy to keep up with ongoing conversations.`}</li>
      <li parentName="ul">{`Users are paying for access via Patreon, so you can be confident that people genuinely takes value from being an active member of the server`}</li>
      <li parentName="ul">{`Everybody that I have come into contact with on the server has been friendly, interesting and enthusiastic`}</li>
      <li parentName="ul">{`Industry news is often shared as “hot drama”, so I am always kept in the loop of the hottest new web development drama`}</li>
    </ul>
    <p>{`Honestly, the above points make the $8 a month to access the server (and support the show) well worth it for me.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;