import React from "react";
import Link from "next/link";

import { Post } from "@/types";

const PostLink: React.FC<Post> = ({ link, module: { meta } }) => (
  <Link href={`/writing${link}`}>
    <a>
      <h2 className="text-white font-extraBold text-lg sm:text-xl">{meta.title}</h2>
      <p className="text-white mt-2 leading-relaxed font-title">{meta.subtitle}</p>
      <span className="text-orange font-bold font-title underline mt-2 block md:mt-3 hover:no-underline">Continue Reading</span>
    </a>
  </Link>
);

export default PostLink;
