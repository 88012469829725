import React from "react";

const Notice: React.FC<{
  title?: string;
}> = ({ title, children }) => (
  <blockquote className=" px-5 py-4 -mx-5 mt-4 italitc border-b border-t bg-black border-orange font-title text-white leading-relaxed sm:mx-0 sm:rounded-md sm:border">
    {title && <h3 className="font-bold">{title}</h3>}
    {children && <p className={title ? "mt-1 leading-relaxed" : "leading-relaxed"}>{children}</p>}
  </blockquote>
);

export default Notice;
