import React from "react";
import Link from "next/link";
import { motion } from "framer-motion";

import { Logo } from "@/components";

import { StyledWrapper, StyledNav } from "./styled";

const Navigation = () => {

  return (
    <div className="relative z-20">
      <StyledWrapper>
        <motion.div
          className="flex justify-between flex-1"
          initial="initial"
          animate="enter"
          exit="exit"
          transition={{ duration: 0.2 }}
          variants={{
            initial: { y: 20, opacity: 0 },
            enter: { y: 0, opacity: 1 },
            exit: { y: 20, opacity: 0 },
          }}
        >
          <Logo />
          <StyledNav>
            <li className="font-title font-bold">
              <Link href="/">Home</Link>
            </li>
            <li className="font-title font-bold">
              <Link href="/writing">Archive</Link>
            </li>
            <li className="font-title font-bold">
              <a href="https://lukebrown.io" target="_blank">Work</a>
            </li>
          </StyledNav>
        </motion.div>
      </StyledWrapper>
    </div>
  );
};

export default Navigation;
